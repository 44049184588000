import React from "react";

const DEFAULT_STRATEGY = "random";
const DEFAULT_ROUNDS = 1000;
const TIME_TICK = 1000;
const REQUEST_DURATION = TIME_TICK / 5;
const STATS_TEMPLATE = { p50: 0, p90: 0, p99: 0, avg: 0, range: 0 };

const MARGIN = { top: 10, right: 10, bottom: 30, left: 30 };

const SELECT_CLASS_NAME = "h-auto border border-gray-300 rounded-lg text-gray-600 text-base block w-full py-1.5 px-2 focus:outline-none";
// const BUTTON_PRIMARY_CLASS_NAME = "text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700";
const BUTTON_PRIMARY_CLASS_NAME = "button";

function useURLParams() {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams) {
        params[key] = value;
    }
    return params;
}


const STRATEGY_LABELS = {
  random: 'Random',
  bestOfTwo: 'Best of two',
}

const STRATEGIES = {
  random: (groupSize, items) => {
    const group = [...Array(groupSize).fill(0)];
    for (let i = 0; i < items; i += 1) {
      const pos = Math.floor(Math.random() * groupSize);
      group[pos] += 1;
    }
    return group;
  },
  bestOfTwo: (groupSize, items) => {
    const group = [...Array(groupSize).fill(0)];
    for (let i = 0; i < items; i += 1) {
      const posA = Math.floor(Math.random() * groupSize);
      const posB = Math.floor(Math.random() * groupSize);
      
      if (group[posA] <= group[posB]) {
        group[posA] += 1; 
      } else {
        group[posB] += 1; 
      }
    }
    return group;
  },
}

function GraphWrapper({ width, height, groupSize }) {
  const [values, setValues] = React.useState([...Array(groupSize).fill(0)]);
  const [selectedStrategy, setSelectedStrategy] = React.useState(DEFAULT_STRATEGY);
  const [selectedRounds, setSelectedRounds] = React.useState(DEFAULT_ROUNDS);
  
  function runSimulation() {
    setValues(STRATEGIES[selectedStrategy](groupSize, selectedRounds));
  }
  
  React.useEffect(() => runSimulation(), [])
  
  return (
    <div className="graph-wrapper">
      <Graph
        height={height}
        width={width}
        values={values}
       />
      <br/>
      <br/>Rounds

      <select
          value={selectedRounds}
          className={SELECT_CLASS_NAME}
          onChange={(e) => setSelectedRounds(parseInt(e.target.value))}
       >
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
        <option value="500">500</option>
        <option value="1000">1000</option>
        <option value="1500">1500</option>
        <option value="2000">2000</option>
      </select>
      <br/>
      Strategy
      <select
          value={selectedStrategy}
          className={SELECT_CLASS_NAME}
          onChange={(e) => setSelectedStrategy(e.target.value)}
       >
        <option value="best">Pick the best</option>
        <option value="random">Random</option>
        <option value="bestOfTwo">Best of two random</option>
      </select>
      <br/>
      <button className={BUTTON_PRIMARY_CLASS_NAME} onClick={runSimulation}>Run</button>
     </div>
  )
}

function Graph({ width, height, values }) {
  return (
    <div className="graph" style={{height, width}}>
      {values.map((e, i) => (
        <span className="graph-column mt-2" key={i}>
          <span className="graph-line" style={{height:Math.min(e*4, 145), backgroundColor: e*4 >= 145 ? '#e57373': '#555' }}></span>
          <span className="graph-count" style={{ color: e*4 >= 145 ? '#e57373' : 'inherit'}}>{e > 999 ? '999+' : e}</span>
        </span>
       ))}
    </div>
  );
}

function BarGraph({ values, label }) {
  return (
    <div className="graph graph-with-bar">
      {label && <span className="graph-label">{label}</span>}
      {values.map((e, i) => (
        <span className="graph-column mt-2" key={i}>
          <span className="graph-line-wrapper">
            <span className="graph-line" style={{height:Math.min(e*3, 145), backgroundColor: e*3 >= 145 ? '#e57373': '#555' }}></span>
          </span>
          <span className="graph-count" style={{ color: e*3 >= 145 ? '#e57373' : 'inherit'}}>{e > 999 ? '999+' : e}</span>
        </span>
       ))}
    </div>
  );
}

function BoxGraph({ values, label }) {
  return (
    <div className="graph">
      {label && <span className="graph-label">{label}</span>}
      {values.map((e, i) => (
        <span className="graph-column mt-2" key={i}>
          <span className="graph-box">
            <span className="graph-count" style={{ color: e*4 >= 200 ? '#e57373' : 'inherit'}}>{e > 999 ? '999+' : e}</span>
          </span>
        </span>
       ))}
    </div>
  );
}

function calculatePercentile(data, percentile) {
  if (percentile < 0 || percentile > 100) {
    throw new Error('Percentile must be between 0 and 100');
  }
  const sortedArray = data.slice().sort((a, b) => a - b);
  const index = Math.ceil((percentile / 100) * sortedArray.length);
  return sortedArray[index - 1];
}

function calculateAverage(data) {
  return Math.round(data.reduce((acc, curr) => acc + curr, 0) / data.length);
}

function calculateRange(data) {
  if (data.length === 0) return null;
  return  Math.max(...data) - Math.min(...data);
}

function formatTime(seconds) {
  if (typeof seconds !== 'number' || seconds < 0) {
    throw new Error('Input must be a non-negative number');
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
  return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
}

const LIVE_STRATEGIES = {
  best: (values, selectedRPS) => {
    const newValues = [...values];
    for (let i = 0; i < selectedRPS; i += 1) {
      let minPos = 0;
      for (let j = 1; j < newValues.length; j += 1) {
        if (newValues[j] < newValues[minPos]) {
          minPos = j;
        }
      }
      newValues[minPos] += 1;
    }
    return newValues;
  },
  random: (values, selectedRPS) => {
    const newValues = [...values];
    for (let i = 0; i < selectedRPS; i += 1) {
      const pos = Math.floor(Math.random() * newValues.length);
      newValues[pos] += 1;
    }
    return newValues;
  },
  bestOfTwo: (values, selectedRPS) => {
    const newValues = [...values];
    for (let i = 0; i < selectedRPS; i += 1) {
      const posA = Math.floor(Math.random() * newValues.length);
      const posB = Math.floor(Math.random() * newValues.length);
      
      if (newValues[posA] <= newValues[posB]) {
        newValues[posA] += 1; 
      } else {
        newValues[posB] += 1; 
      }
    }
    return newValues;
  },
}

function generateStats(values) {
  const initialStats = {};
  for (let i = 0; i < values.length; i += 1) {
    initialStats[i] = STATS_TEMPLATE;
  }
  return initialStats;
}

function generateInitialValues(count, size) {
  const values = [];
  for (let i = 0; i < count; i += 1) {
    values.push([...Array(size).fill(0)]);
  }
  return values;
}

function ComboGraphWrapper({ }) {
  const url = useURLParams();

  const [groupSize, setGroupSize] = React.useState(url.group_size ? parseInt(url.group_size) : 10);
  const [graphCount, setGraphCount] = React.useState(url.graph_count ? parseInt(url.graph_count) : 1);
  const [values, setValues] = React.useState(generateInitialValues(graphCount, groupSize));
  const [selectedStrategies, setSelectedStrategies] = React.useState(
    [
      url.strategy_a ? url.strategy_a : 'random',
      url.strategy_b ? url.strategy_b : 'bestOfTwo'
    ]
  );
  const [selectedStrategy, setSelectedStrategy] = React.useState(DEFAULT_STRATEGY);
  const [ticker, setTicker] = React.useState(null);
  const [elapsedSeconds, setElapsedSeconds] = React.useState(0);
  const [stats, setStats] = React.useState(generateStats(values));
  const [items, setItems] = React.useState(url.items ? parseInt(url.items) : 50);
  
  function resetValues() {
    const newValues = generateInitialValues(graphCount, groupSize);
    setValues(newValues);
    setStats(generateStats(newValues));
  }
  
  function runSimulation() {
    setValues(() => {
      const newValues = [];
      for (let i = 0; i < graphCount; i += 1) {
        newValues.push(STRATEGIES[selectedStrategies[i]](groupSize, items));
      }

      setStats(() => {
        const stats = {};
        for (let i = 0; i < newValues.length; i++) {
          stats[i] = {
            p50: calculatePercentile(newValues[i], 50),
            p90: calculatePercentile(newValues[i], 90),
            p99: calculatePercentile(newValues[i], 99),
            avg: calculateAverage(newValues[i]),
            range: calculateRange(newValues[i]),
          };
        }
        return stats;
      });

      return newValues;
    });
  }
  
  function resetSimulation() {
    setStats(STATS_TEMPLATE);
    resetValues();
  };
  
  function handleGroupSizeChange(e) {
    setGroupSize(() => {
      setStats(STATS_TEMPLATE);
      resetValues();
      return parseInt(e.target.value);
    });
  }
  
  function handleSelectStrategy(index, value) {    
    setSelectedStrategies(prevSelectedStrategies => {
      const newStrategies = [...prevSelectedStrategies];
      newStrategies[index] = value;
      resetSimulation();
      return newStrategies;
    });
  }
  
  return (
    <div className="graph-wrapper">
      <div className="tray">
        <div className="tray-controls tray-controls-x">
          <div>
            <span class="text-slate-500 text-md">Balls</span>
            <select
                value={items}
                className={SELECT_CLASS_NAME}
                onChange={(e) => setItems(parseInt(e.target.value))}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="80">80</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="400">400</option>
            </select>
          </div>
          <div>
          <span class="text-slate-500 text-md">Bins</span>
            <select
                value={groupSize}
                className={SELECT_CLASS_NAME}
                onChange={(e) => handleGroupSizeChange(e)}
            >
              <option value="2">2</option>
              <option value="5">5</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
          <div>
            <button className={BUTTON_PRIMARY_CLASS_NAME} onClick={runSimulation} style={{marginRight:'8px'}}>Run</button>
            <button className={BUTTON_PRIMARY_CLASS_NAME} onClick={resetSimulation}>Reset</button>
          </div>
      </div>
    </div>

    <hr class="h-px mt-4 mb-8 bg-gray-200 border-0 dark:bg-gray-700 w-96" />

        <div className="graph-side-by-side">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="relative mx-4">
              <BarGraph values={values[0]} />
              <select
                  value={selectedStrategies[0]}
                  className="absolute w-auto h-auto p-[4px] top-[8px] bg-[#fff] mx-[auto] my-[0] left-[8px]"
                  onChange={(e) => handleSelectStrategy(0, e.target.value)}
               >
                <option value="random">Random</option>
                <option value="bestOfTwo">Best of two random</option>
              </select>
            </div>
            
            {
              graphCount > 1 && (
                <div className="relative">
                  <BarGraph values={values[1]} />
                  <select
                      value={selectedStrategies[1]}
                      className="absolute w-auto h-auto p-[4px] top-[8px] bg-[#fff] mx-[auto] my-[0] left-[8px]"
                      onChange={(e) => handleSelectStrategy(1, e.target.value)}
                   >
                    <option value="random">Random</option>
                    <option value="bestOfTwo">Best of two random</option>
                  </select>
                </div>
              )
            }
          </div>

<div class="bg-white mt-4 rounded border border-gray-100 rounded-lg border-2 p-4">
  <table className="table-auto">
    <thead>
      <tr>
      <th className="text-center py-2 px-4 min-w-28">{STRATEGY_LABELS[selectedStrategies[0]]}</th>
        <th className="text-center py-2 px-4 min-w-28"></th>
        {
          graphCount > 1 && (
            <th className="text-center py-2 px-4 min-w-28">{STRATEGY_LABELS[selectedStrategies[1]]}</th>
          )
        }
      </tr>
    </thead>
    <tbody className="bg-white">
      {
        ["p90", "p99", "range", "avg"].map(stat => (
          <tr>
            <td>
              <span className={`flex py-2 justify-center ${graphCount > 1 && stats[0][stat] < stats[1][stat] ? 'bg-emerald-50' : ''}`}>
                {stats[0][stat]}
              </span> 
            </td>
            <td className="px-8 py-2 flex justify-center bg-zinc-50 text-slate-500">{stat}</td>
            {
              graphCount > 1 && (
                <td>
                  <span className={`flex py-2 justify-center ${graphCount > 1 && stats[1][stat] < stats[0][stat] ? 'bg-emerald-50' : ''}`}>
                    {stats[1][stat]}
                  </span>
                </td>
              )
            }
          </tr> 
        ))
      }
    </tbody>
  </table>
</div>
      </div>
   </div>
  );
}

function LiveGraphWrapper({ width, height }) {
  const url = useURLParams();

  const [groupSize, setGroupSize] = React.useState(url.group_size ? parseInt(url.group_size) : 10);
  const [values, setValues] = React.useState([...Array(groupSize).fill(0)]);
  const [selectedStrategy, setSelectedStrategy] = React.useState(DEFAULT_STRATEGY);
  const [selectedRPS, setSelectedRPS] = React.useState(50);
  const [selectedRPSFulfillment, setSelectedRPSFulfillment] = React.useState(5);
  const [ticker, setTicker] = React.useState(null);
  const [elapsedSeconds, setElapsedSeconds] = React.useState(0);
  const [stats, setStats] = React.useState(STATS_TEMPLATE);
  const processedCount = Math.floor(TIME_TICK / REQUEST_DURATION);
  const [simulationSpeed, setSimulationSpeed] = React.useState(TIME_TICK);
  
  function runSimulation() {
    if (ticker !== null) {
      return;
    }

    const id = setInterval(() => {
      // Add requests
      setValues(currentValues => {
        const newVals = LIVE_STRATEGIES[selectedStrategy](currentValues, selectedRPS);
        return newVals;
      });
      
      // Deque requests
      setValues(currentValues => {
        const newValuesD = dequeRequests(currentValues, processedCount);
        setStats({
          p50: calculatePercentile(newValuesD, 50),
          p90: calculatePercentile(newValuesD, 90),
          p99: calculatePercentile(newValuesD, 99),
          avg: calculateAverage(newValuesD),
        });
        return newValuesD;
      });
      
      setElapsedSeconds(s => s + 1);
    }, simulationSpeed);
    
    setTicker(id);
  }
  
  function dequeRequests(currentValues, processedCount) {
    const newValues = [...currentValues];
    for (let i = 0; i < newValues.length; i += 1) {
      newValues[i] = Math.max(newValues[i] - processedCount, 0);
    }
    return newValues;
  }

  function stopSimulation() {
    clearInterval(ticker);
    setTicker(null);
  }
  
  function resetSimulation() {
    stopSimulation();
    setElapsedSeconds(0);
    setStats(STATS_TEMPLATE);
    setValues([...Array(groupSize).fill(0)]);
  };
  
  function handleGroupSizeChange(e) {
    stopSimulation();
    const newGroupSize = parseInt(e.target.value);
    setGroupSize(newGroupSize);
    setValues([...Array(newGroupSize).fill(0)]);
  }
  
  function handleChangeSimulationSpeed(e) {
    stopSimulation();
    const newSimulationSpeed = parseInt(e.target.value);
    setSimulationSpeed(newSimulationSpeed);
    resetSimulation();
  }
  
  return (
    <div className="graph-wrapper">

        <div className="tray-controls">
          <div>
          <span class="text-slate-500 text-md">Incoming RPS</span>
            <select
                value={selectedRPS}
                className={SELECT_CLASS_NAME}
                onChange={(e) => setSelectedRPS(parseInt(e.target.value))}
            >
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="1500">1500</option>
              <option value="2000">2000</option>
            </select>
          </div>
          {/* <div>Per server RPS fulfillment
            <select
                value={selectedRPSFulfillment}
                className={SELECT_CLASS_NAME}
                onChange={(e) => setSelectedRPSFulfillment(parseInt(e.target.value))}
            >
              <option value="0">0 (no dequeue)</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div> */}
          <div>
            <span class="text-slate-500 text-md">Simulation Speed</span>
            <select
                value={simulationSpeed}
                className={SELECT_CLASS_NAME}
                onChange={(e) => handleChangeSimulationSpeed(e)}
            >
              <option value="1000">1x</option>
              <option value="200">5x</option>
              <option value="100">10x</option>
              <option value="50">20x</option>
              <option value="20">50x</option>
              <option value="10">100x</option>
              <option value="5">200x</option>
            </select>
          </div>
          <div>
          <span class="text-slate-500 text-md">Servers</span>
            <select
                value={groupSize}
                className={SELECT_CLASS_NAME}
                onChange={(e) => handleGroupSizeChange(e)}
            >
              <option value="2">2</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
          <div>
            <span class="text-slate-500 text-md">Strategy</span>
            <select
                value={selectedStrategy}
                className={SELECT_CLASS_NAME}
                onChange={(e) => setSelectedStrategy(e.target.value)}
            >
              <option value="best">Least load</option>
              <option value="random">Random</option>
              <option value="bestOfTwo">Best of two rand</option>
            </select>
          </div>
          <div>
            <button className={BUTTON_PRIMARY_CLASS_NAME} onClick={runSimulation} style={{marginRight:'8px'}}>Run</button>
            <button className={BUTTON_PRIMARY_CLASS_NAME} onClick={stopSimulation} style={{marginRight:'8px'}}>Stop</button>
            <button className={BUTTON_PRIMARY_CLASS_NAME} onClick={resetSimulation}>Reset</button>
          </div>
        </div>

      <div class="mt-2 italic text-slate-500 text-sm">
        (servers can process up to 5 RPS)
      </div>

      <hr class="h-px my-4 mb-8 bg-gray-200 border-0 dark:bg-gray-700 w-96" />

      <BarGraph values={values} />
      
      <div className="tray mt-8">
        <div class="bg-white ml-8 rounded border border-gray-100 rounded-lg border-2 p-4">
          <table className="table-auto">
              <thead>
                <tr>
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr>
                  <td>
                    <span className={`flex px-4 justify-center`}>
                      <span className="text-mono">{formatTime(elapsedSeconds)}</span>
                    </span> 
                  </td>
                  <td className="py-2 px-4 flex justify-center bg-zinc-50 text-slate-500">Elapsed time</td>
                </tr> 
                {
                  ["p50", "p90", "p99", "avg"].map(stat => (
                    <tr>
                      <td>
                        <span className={`flex px-4 justify-center`}>
                          {stats[stat]}
                        </span> 
                      </td>
                      <td className="py-2 px-4 flex justify-center bg-zinc-50 text-slate-500">{stat}</td>
                    </tr> 
                  ))
                }
              </tbody>
          </table>
        </div>
    </div>
  </div>
  )
}

export const App = () => {
  const url = useURLParams();
  const type = url.type || "ComboGraphWrapper";

  if (type === "LiveGraph") {
    return (
      <div className="box">
        <LiveGraphWrapper />
      </div>
    );
  }

  // Fallback
  return(
    <div>
      <div className="box">
        <ComboGraphWrapper />
      </div>
    </div>
  );
}
